import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const LoginCSS = styled.div`

.login-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  font-family: 'Ninja', sans-serif;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 60px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.8);
  animation: borderGlow 2s infinite;
  position: relative;
  font-family: 'Ninja', sans-serif;
}

.login-form:before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: linear-gradient(45deg, #ff0000, transparent, #00ac96);
  border-radius: 24px;
  z-index: -1;
  filter: blur(10px);
  opacity: 0.7;
  animation: pulsate 2s linear infinite;
}

.login-form input {
  padding: 14px;
  border-radius: 20px;
  background: transparent;
  color: rgb(173, 165, 165);
  outline: none;
  text-align: center;
  animation: pulsate 3s infinite;
  font-family: 'Ninja', sans-serif;
  border: none;
  font-size: 1.2em;
}

.login-form button {
  padding: 14px;
  background: linear-gradient(45deg, #1a1a1a, #2d2d2d);
  border: none;
  border-radius: 20px;
  color: rgb(177, 177, 177);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0,188,212);
  font-family: 'Ninja', sans-serif;
  animation: pulsate 4s infinite;
  font-size: 1.2em;
}

.login-error {
  color: red;
  text-align: center;
  font-family: 'Ninja', sans-serif;
  margin-bottom: 15px;
  font-size: 1em;
  font-family: 'Ninja', sans-serif;
}

@keyframes borderTravel {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* Media query */
@media (max-width: 768px) {
  .login-form {
    padding: 15px;
  }
  
  .login-form input {
    padding: 10px;
    font-size: 0.8em;
  }
  
  .login-form button {
    padding: 8px;
    font-size: 0.7em;
  }
}
`;


const inputVariants = {
  hidden: { x: '-100vw', opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { type: 'spring', stiffness: 120 } }
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactor, setTwoFactor] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/authenticate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      const result = await response.json();
  
      if (result.error) {
        setLoginError(result.error);
      } else {
        console.log('User logged in:', result.user);
        setLoginError('');
        navigate('/prechecks');
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoginError('Login failed.');
    }
  };
  

  return (
    <LoginCSS>
      <motion.div
        className="login-container"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.form
          className="login-form"
          onSubmit={handleLogin}
        >
          {loginError && <div className="login-error">{loginError}</div>}
          <motion.input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          />
         
          <div style={{ position: 'relative' }}>
            <motion.input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variants={inputVariants}
              initial="hidden"
              animate="visible"
            />
            <button 
              onClick={() => setShowPassword(!showPassword)} 
              type="button"
              style={{ 
                position: 'absolute', 
                right: -25, 
                top: 15, 
                background: 'none', 
                border: 'none',
                padding: '1px', // Adjust padding as needed
                fontSize: '1em', // Adjust font size as needed
                cursor: 'pointer' // Change cursor to pointer to indicate it's clickable
              }}
            >
              {showPassword ? '🥷' : '👀'}
            </button>
          </div>
          <motion.input
            type="text"
            placeholder="2FA Code"
            value={twoFactor}
            onChange={(e) => setTwoFactor(e.target.value)}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          >
            Log in to ninjai
          </motion.button>
        </motion.form>
      </motion.div>
    </LoginCSS>
  );
};

export default Login;


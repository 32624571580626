import React, { useState, useEffect } from 'react';
import AccountLinker from './AccountLinker';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import backgroundImage from '../images/linkTelegram.webp';

const PreChecksCSS = styled.div`
.prechecks-container {
    display: flex-box;
    align-items: center;
    justify-content: center;
    min-height: 10vh;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center;
  }
`;

const PreChecks = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [isLinked, setIsLinked] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('/api/get-session');
                const { user } = response.data;
                if (user) {
                    setUserInfo(user);
                    checkUserLink(user.id);
                }
            } catch (error) {
                console.error('Error fetching session:', error);
            }
        };

        const checkUserLink = async (userId) => {
            try {
                const response = await axios.post('/api/check-user-link', { userId });
                const { linked } = response.data;
                if (!linked) {
                    setIsLinked(false);
                } else {
                    setIsLinked(true);
                    navigate('/dashboard');
                }
            } catch (error) {
                console.error('Error checking user link:', error);
                setIsLinked(false);
            }
        };

        fetchUserData();
    }, [navigate]);

    const handleLinkSuccess = () => {
        setIsLinked(true);
        navigate('/dashboard');
    };

    const handleLinkFailure = (errorMessage) => {
        console.error('Link failure:', errorMessage);
    };

    if (!userInfo) return <div className="prechecks-container">Loading...</div>;

    if (!isLinked) {
        return (
            <PreChecksCSS>
                <div className="prechecks-container">
                    <AccountLinker
                        userId={userInfo.id}
                        onLinkSuccess={handleLinkSuccess}
                        onLinkFailure={handleLinkFailure}
                    />
                </div>
            </PreChecksCSS>
        );
    }
    return null;
};

export default PreChecks;
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { createClient } from '@supabase/supabase-js';
import styled from 'styled-components';

const SignupCSS = styled.div`
.signup-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  font-family: 'Ninja', sans-serif;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 60px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.8);
  animation: borderGlow 2s infinite;
  position: relative;
  font-family: 'Ninja', sans-serif;
}

.signup-form:before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: linear-gradient(45deg, #ff0000, transparent, #00ac96);
  border-radius: 24px;
  z-index: -1;
  filter: blur(10px);
  opacity: 0.7;
  animation: pulsate 2s linear infinite;
}

.signup-form input {
  padding: 14px;
  border-radius: 20px;
  background: transparent;
  color: rgb(173, 165, 165);
  outline: none;
  text-align: center;
  animation: pulsate 3s infinite;
  font-family: 'Ninja', sans-serif;
  border: none;
  font-size: 1.2em;
}

.signup-form button {
  padding: 14px;
  background: linear-gradient(45deg, #1a1a1a, #2d2d2d);
  border: none;
  border-radius: 20px;
  color: rgb(177, 177, 177);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0,188,212);
  font-family: 'Ninja', sans-serif;
  animation: pulsate 4s infinite;
  font-size: 1.2em;
}

@keyframes pulsate {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

@keyframes borderGlow {
  0% { box-shadow: 0 0 5px #ff0000; }
  50% { box-shadow: 0 0 20px #ff0000; }
  100% { box-shadow: 0 0 5px #ff0000; }
}

@media (max-width: 768px) {
  .signup-form {
    padding: 15px;
  }
  
  .signup-form input {
    padding: 10px;
    font-size: 0.8em;
  }
  
  .signup-form button {
    padding: 8px;
    font-size: 0.7em;
  }
}
`;

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const inputVariants = {
  hidden: { x: '-100vw', opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { type: 'spring', stiffness: 120 } }
};

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSignup = async (e) => {
    e.preventDefault();
    // Add validation logic here if necessary
    const { error } = await supabase.auth.signUp({ email, password });
    if (error) throw error;
  };

  return (
    <SignupCSS>
      <motion.div
        className="signup-container"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.form
          className="signup-form"
          onSubmit={handleSignup}
        >
          <motion.input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          />
          <div style={{ position: 'relative' }}>
            <motion.input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variants={inputVariants}
              initial="hidden"
              animate="visible"
            />
            <button 
              onClick={() => setShowPassword(!showPassword)} 
              type="button"
              style={{ 
                position: 'absolute', 
                right: -25, 
                top: 15, 
                background: 'none', 
                border: 'none',
                padding: '1px', 
                fontSize: '1em',
                cursor: 'pointer' 
              }}
            >
              {showPassword ? '🥷' : '👀'}
            </button>
          </div>
          <div style={{ position: 'relative' }}>
            <motion.input
              type={showPassword ? "text" : "password"}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              variants={inputVariants}
              initial="hidden"
              animate="visible"
            />
            <button 
              onClick={() => setShowPassword(!showPassword)} 
              type="button"
              style={{ 
                position: 'absolute', 
                right: -25, 
                top: 15, 
                background: 'none', 
                border: 'none',
                padding: '1px',
                fontSize: '1em', 
                cursor: 'pointer' 
              }}
            >
              {showPassword ? '🥷' : '👀'}
            </button>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          >
            Sign Up
          </motion.button>
        </motion.form>
      </motion.div>
    </SignupCSS>
  );
};

export default Signup;
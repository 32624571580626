// useFullScreen.js
import { useState } from "react";


export const useFullScreen = () => {
    const [isFullScreen, setFullScreen] = useState(false);
  
    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen().catch(e => {
          console.error(`Error attempting to enable full-screen mode: ${e.message} (${e.name})`);
        });
        setFullScreen(true);
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
        setFullScreen(false);
      }
    };
  
    return { isFullScreen, toggleFullScreen };
  };
  
import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Login from '../../Login/jsx/Login';
import Signup from '../../SignUp/SignUp/jsx/Signup';
import ForgotPassword from '../../ForgotPassword/PreAuthEmail/jsx/ForgotPassword';
import image1 from '../images/login.webp';
import image2 from '../images/signup.webp';
import image3 from '../images/forgotpassword.webp';
import styled from 'styled-components';

const FrameCSS = styled.div`
.frame-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: black;
  z-index: 1;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.loading-screen {
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
background-color: black;
font-family: 'Ninja', sans-serif;
font-size: 30px;
}

.loading-screen div {
font-size: 8rem; 
}
`;

const Frame = () => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const backgroundImages = useMemo(() => [image1, image2, image3], []);
  const modals = [<Login key="login" />, <Signup key="signup" />, <ForgotPassword key="forgot" />];

  useEffect(() => {
    Promise.all(backgroundImages.map(image => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = resolve;
        img.onerror = reject;
      });
    }))
      .then(() => setLoading(false))
      .catch(err => console.error("Failed to load images", err));
  }, [backgroundImages]);

  const paginate = (newDirection) => {
    setPage(currentPage => (currentPage + newDirection + modals.length) % modals.length);
  };

  const postSwipeAnimation = {
    hidden: {
      scale: 1,
      opacity: 0,
      x: -100,
      duration: 1.5
    },
    visible: {
      scale: 1.2,
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 30,
        damping: 20,
        duration: 1
      }
    }
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

  const loadingAnimation = {
    initial: {
      scale: 1,
      opacity: 1
    },
    animate: {
      scale: [1, 1.2, 1],
      opacity: [1, 0.8, 1],
      transition: {
        duration: 1,
        ease: "easeInOut",
        repeat: Infinity,
      }
    }
  };

  if (loading) {
    return (
      <FrameCSS>
        <div className="loading-screen">
          <motion.div variants={loadingAnimation} initial="initial" animate="animate">
            🥷
          </motion.div>
          <div>Loading the NinjAi web app...</div>
        </div>
      </FrameCSS>
    );
  }

  return (
    <FrameCSS>
      <div className="frame-container">
        <AnimatePresence>
          <motion.div
            key={backgroundImages[page]}
            className="background"
            style={{ backgroundImage: `url(${backgroundImages[page]})` }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.5 }}
          />
        </AnimatePresence>

        <AnimatePresence>
          {modals.map((Modal, index) => (
            page === index && (
              <motion.div
                key={index}
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={postSwipeAnimation}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x);
                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}
              >
                {Modal}
              </motion.div>
            )
          ))}
        </AnimatePresence>
      </div>
    </FrameCSS>
  );
};

export default Frame;
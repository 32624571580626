import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const ForgotPasswordPostCSS = styled.div`

.forgot-password-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  font-family: 'Ninja', sans-serif;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 60px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.8);
  animation: borderGlow 2s infinite;
  position: relative;
  font-family: 'Ninja', sans-serif;
}

.forgot-password-form:before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: linear-gradient(45deg, #ff0000, transparent, #00ac96);
  border-radius: 24px;
  z-index: -1;
  filter: blur(10px);
  opacity: 0.7;
  animation: pulsate 2s linear infinite;
}

.forgot-password-form input {
  padding: 14px;
  border-radius: 20px;
  background: transparent;
  color: rgb(173, 165, 165);
  outline: none;
  text-align: center;
  animation: pulsate 3s infinite;
  font-family: 'Ninja', sans-serif;
  border: none;
  font-size: 1.2em;
}

.forgot-password-form button {
  padding: 14px;
  background: linear-gradient(45deg, #1a1a1a, #2d2d2d);
  border: none;
  border-radius: 20px;
  color: rgb(177, 177, 177);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0,188,212);
  font-family: 'Ninja', sans-serif;
  animation: pulsate 4s infinite;
  font-size: 1.2em;
}

@keyframes pulsate {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

@keyframes borderGlow {
  0% { box-shadow: 0 0 5px #ff0000; }
  50% { box-shadow: 0 0 20px #ff0000; }
  100% { box-shadow: 0 0 5px #ff0000; }
}

@media (max-width: 768px) {
  .forgot-password-form {
    padding: 15px;
  }
  
  .forgot-password-form input {
    padding: 10px;
    font-size: 0.8em;
  }
  
  .forgot-password-form button {
    padding: 8px;
    font-size: 0.7em;
  }
}
`;

const inputVariants = {
  hidden: { x: '-100vw', opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { type: 'spring', stiffness: 120 } }
};

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword }),
      });
  
      const result = await response.json();
  
      if (result.error) {
        alert("Error updating password: " + result.error);
      } else {
        alert("Password updated successfully!");
      }
    } catch (error) {
      console.error('Error updating password:', error);
      alert("Password update failed.");
    }
  };
  

  return (
    <ForgotPasswordPostCSS>
      <motion.div className="forgot-password-container">
        <motion.form className="forgot-password-form" onSubmit={handlePasswordReset}>
          <motion.input
            type="password"
            placeholder="New password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          >
            Update Password
          </motion.button>
        </motion.form>
      </motion.div>
    </ForgotPasswordPostCSS>
  );
};

export default PasswordReset;
import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from '@emotion/styled';
import { gsap } from 'gsap';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
`;

const Text = styled(motion.p)`
  @font-face {
    font-family: 'Ninja';
    font-weight: normal;
    font-style: normal;
  }
  font-size: 6vw; 
  color: white; 
  text-align: center;
  font-family: 'Ninja', sans-serif;
  font-weight: normal;
  overflow-wrap: break-word; 
  word-wrap: break-word;
`;

const useLetterColor = () => {
  const colors = [

    "#be00ab",
    "#b200ba",
    "#a500c9"
  ];

  const [colorIndex, setColorIndex] = useState(0);

  const nextColor = () => {
    setColorIndex((colorIndex + 1) % colors.length);
  };

  return { color: colors[colorIndex], nextColor };
};


const paragraphsData = [
  {
    english: "🥷🏿 Fuck you, ya lil bitch ass punk... 🥷🏿",
    japanese: "🥷🏿座って、旅の始まりとなる話をしましょう🥷🏿",
  },
  {
    english: "Your cunt smells like freshly picked blueberries and extra chromosones",
    japanese: "四ヶ月前、全てのウィーブチャットDefiプロジェクトが荒廃していることに気づきました",
  },
  {
    english: "This is what happens when you fuck a stranger in the ass, Larry 📉",
    japanese: "四ヶ月前、全てのウィーブチャットDefiプロジェクトが荒廃していることに気づきました",
  },
  {
    english: "I am going to wage war on your mum's florist shop and make sure the roses die",
    japanese: "そして考えさせられました...自分だけで作るだけでなく、それ以前の何よりも良く作ることができる",
  },
  { 
    english: "As we speak, I have my international network of tranny spies and they're hungry",
    japanese: "そして、何週間も何週間も、私が考えることができたのはとネットワーのはとネットワーのはとネットワーのはとネットワークだけでした",
  },
  {
    english: "One day - I will take your sister for dinner and convince the waitress to glass her in the swede exactly seven times...hard",
    japanese: "美しいUIを作り、ワイフだけでなく一群のキャラクターを生命に呼び覚ますことができるのに、なぜ自分をひどい詐欺的なテレグラムグループに制限するのでしょう",
  },
  {
    english: "Have you ever had a shit so girthy that you think you'll need stitches? Nah, me neither",
    japanese: "そして、何週間も何週間も、私が考えることができたのはとネットワークだけでットワークだけでットワークだけでした",
  },
  {
    english: "This one time at band camp - we just sat around bored because we're not sexual deviants. We only fucked when Mr. Davis wanted to",
    japanese: "美しいUIを作り、ワイフだけでなく一群のキャラクターを生命に呼び覚ますことができるのに、なぜ自分をひどい詐欺的なテレグラムグループに制限するのでしょう",
  },
  {
    english: "Your mums florist shop is fine, i was only joking. I'd never mistreat roses like that... ever",
    japanese: "そして、何週間も何週間も、私が考えることができたのはことができたのはことができたのはことができたのはネットワークだけでした",
  },
];

const AnimatedText = ({ data, color }) => {
  const textRef = useRef(null);

  useEffect(() => {
    const englishArray = data.english.split('');
    const japaneseArray = data.japanese.split('');
    let englishIndex = 0;
    let japaneseIndex = 0;

    const timer = setInterval(() => {
        if (englishIndex < englishArray.length) {
            if (englishArray[englishIndex] === ' ') {
                japaneseArray.splice(japaneseIndex, 0, ' ');
                englishIndex++;
            } else if (japaneseArray[japaneseIndex] !== ' ') {
                japaneseArray[japaneseIndex] = englishArray[englishIndex];
                englishIndex++;
                japaneseIndex++;
            } else {
                japaneseIndex++;
            }
            textRef.current.innerText = japaneseArray.join('');
        } else {
            clearInterval(timer);
        }
    }, 30);

    gsap.to(textRef.current, {
        color: color,
        duration: 0.1,
        ease: 'power3.',
    });

    return () => clearInterval(timer);
}, [data, color]);
  return (
    <Text
      ref={textRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    />
  );
};

const TextTransitionComponent = () => {
  const [index, setIndex] = useState(0);
  const [setModalVisible] = useState(false);
  const { color, nextColor } = useLetterColor();

  const handleClick = () => {
    const nextIndex = (index + 1) % paragraphsData.length;
    setIndex(nextIndex);
    nextColor();
    if (nextIndex === 0) {
      setModalVisible(true);
    }
  };


  return (
    <Container onClick={handleClick}>
      <AnimatePresence mode='wait'>
          <AnimatedText
            key={index}
            data={paragraphsData[index]}
            color={color}
          />
        
      </AnimatePresence>
    </Container>
  );
};

export default TextTransitionComponent;

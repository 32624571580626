import React, { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import styled from 'styled-components';
import { useFullScreen } from "../Dropdown/DropDownComponents/FullScreenToggle/FullScreen";

const DropDownMenuCSS = styled.div`

body {
  background: linear-gradient(180deg, #0055ff 0%, rgb(0, 153, 255) 100%);
  overflow: hidden;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 270px;
  background: #008080;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 9px;
  left: 5.5px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 30px;
  height: 40px;
  border-radius: 20%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 30px;
  flex: 3;
  display: flex;
  justify-content: center; 
  align-items: center; 
  font-family: 'Ninja', sans-serif;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
`;

// MenuItem Component (Updated for Fullscreen Toggle)
const MenuItem = ({ i, isFullScreenToggle }) => {
  const { isFullScreen, toggleFullScreen } = useFullScreen();
  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  const colors = ["#55f3ce", "#57f3de", "#50eef3", "#46e2f3", "#49d9f3"];
  const style = { border: `3px solid ${colors[i % colors.length]}` }; // Ensure the color selection is always within range

  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={isFullScreenToggle ? toggleFullScreen : undefined}
    >
      <div className="icon-placeholder" style={style}>
        {/* Displaying icon for fullscreen toggle within a span */}
        {isFullScreenToggle && (
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            {isFullScreen ? "❌" : "✅"}
          </span>
        )}
      </div>
      <div className="text-placeholder" style={style}>
        {/* Displaying text for fullscreen toggle */}
        {isFullScreenToggle ? (isFullScreen ? "Exit Fullscreen" : "Enter Fullscreen") : ""}
      </div>
    </motion.li>
  );
};

// MenuToggle Component
const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

const MenuToggle = ({ toggle }) => (
  <button onClick={toggle}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    </svg>
  </button>
);

// Navigation Component (Updated)
const Navigation = () => {
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  const itemIds = [0, 1, 2, 3, 4, 'fullscreen']; // 'fullscreen' as a special id

  return (
    <motion.ul variants={variants}>
      {itemIds.map(i => (
        <MenuItem i={i} key={i} isFullScreenToggle={i === 'fullscreen'} />
      ))}
    </motion.ul>
  );
};

// DropDown Component
const DropDown = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 30px 30px)`,
      transition: {
        type: "spring",
        stiffness: 4,
      }
    }),
    closed: {
      clipPath: "circle(20px at 30px 30px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 250,
        damping: 40
      }
    }
  };

  return (
    <DropDownMenuCSS>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        ref={containerRef}
      >
        <motion.div className="background" variants={sidebar} />
        <Navigation />
        <MenuToggle toggle={() => toggleOpen()} />
      </motion.nav>
    </DropDownMenuCSS>
  );
};

export default DropDown;
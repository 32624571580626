import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';

const Ninja = styled(motion.span)`
  position: absolute;
  font-size: ${({ size }) => `${size}px`};
  color: white;
`;

const Background = () => {
    const [ninjas, setNinjas] = useState([]);
  
    useEffect(() => {
      createNinjas();
    }, []);
  
    const createNinjas = () => {
      const tempNinjas = [];
      for (let i = 0; i < 100; i++) {
        tempNinjas.push({
          id: i,
          size: Math.random() * 4 + 6,
          top: Math.random() * 100 + 'vh',
          left: Math.random() * 100 + 'vw',
          duration: Math.random() * 1 + 0.1,
        });
      }
      setNinjas(tempNinjas);
    };
  
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          overflow: 'show',
          backgroundColor: 'black',
          zIndex: -1,
        }}
      >
        {ninjas.map((ninja) => (
          <Ninja
            key={ninja.id}
            size={ninja.size}
            style={{
              top: ninja.top,
              left: ninja.left,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1, 0] }}
            transition={{
              repeat: Infinity,
              duration: ninja.duration,
              repeatType: 'loop',
              repeatDelay: Math.random() * 2,
            }}
          >
            🥷
          </Ninja>
        ))}
        
      </div>
    );
  };
  
  export default Background;

import React from 'react';
import { motion } from 'framer-motion';
import DropDown from '../../Dropdown/DropDownMenu';
import styled from 'styled-components';

const HeaderCSS = styled.div`
.header-bar {
  background-color: rgb(0, 0, 0);
  height: 70%;
  display: flex;
  color: rgb(188, 188, 188);
  position: sticky; 
}
`;

const HeaderBar = () => {
  return (
    <HeaderCSS>
      <motion.div
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="header-bar"
      >
        <DropDown />
      </motion.div>
    </HeaderCSS>
  );
};

export default HeaderBar;
import React from 'react';
import HeaderBar from '../../Components/HeaderBar/Bar/jsx/HeaderBar';
// import MainContainer from '../../Components/MainContainer/ContainerFramework/jsx/MainContainer';
import Home from '../../Components/Modules/HomePage/jsx/Home';

const Dashboard = () => {
    return (
            <div className="dashboard">
                <HeaderBar />
                <Home />
                
                {/* <MainContainer /> */}
            </div>
    );
};

export default Dashboard;
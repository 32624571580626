import React, { useState, useEffect, useRef } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { gsap } from 'gsap';
import axios from 'axios';
import AboutText from './About';

import Background from './Background';
import button1 from '../images/stats.webp';
import button2 from '../images/groupManagement.webp';
import button3 from '../images/news.webp';
import button4 from '../images/games.webp';
import button5 from '../images/games.webp';
import button6 from '../images/games.webp';
import button7 from '../images/deposit.webp';
import button8 from '../images/wiki.webp';
import button9 from '../images/burn.webp';

const buttons = [button1, button2, button3, button4, button5, button6, button7, button8, button9];

// Create a bounce animation for text
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

const LoadingText = styled.p`
  font-family: 'Ninja', sans-serif;
  color: #ff6347; /* Tomato red color */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centering the text */
  text-align: center;
  font-size: 3em; /* Making the text bigger */
  animation: ${bounce} 2s linear infinite; /* Add bouncing animation */

  @media (max-width: 768px) {
    font-size: 1.5em; /* Adjust the font size for smaller screens */
  }
`;

const Spinner = styled.div`
border: 16px solid #40e0d0; /* Turquoise (cyan) color */
border-top: 16px solid #008080; /* Teal color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


function Loading({ progress }) {
  return (
    <>
      <LoadingContainer>
        <LoadingBar 
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.2, ease: "easeOut" }}
        />
      </LoadingContainer>
      <Spinner />
      {/* Put some text here later, it's being a cunt to center */}
      <LoadingText></LoadingText> 
    </>
  );
}

const KawaiiModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 5;
`;

const CloseButton = styled(motion.button)`
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  font-size: 28px;
  color: white;
  cursor: pointer;
  font-family: Ninja, monospace;
  z-index: 6;
  background-color: transparent;
`;

const GridWrapper = styled.div`
  position: relative;
  width: ${({ size, gap }) => (size + gap) * 2.9}px;
  height: ${({ size, gap }) => (size + gap) * 3}px;
  display: flexbox;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column; /* stack items in a column for small screens */
  }
`;

const grid = [[0, 1, 2], [3, 4, 5], [6, 7, 8]];
const size = Math.min(Math.max(document.documentElement.clientWidth * 0.265, 75), 185);

const gap = 40;
// Updated colors array with red, orange, cyan, and green
const colors = [
    "#ff0000", // Vivid Red
    "#ff0000", // Vivid Red
    "#ff0000", // Vivid Red
    "#ff4500", // Bright Orange
    "#ff4500", // Bright Orange
    "#ff4500", // Bright Orange
    "#00ffff", // Bright Cyan
    "#00ffff", // Bright Cyan
    "#00ffff", // Bright Cyan
    // "#ff4500", // Bright Orange
    // "#00ffff", // Bright Cyan
    "#00ff00", // Vivid Green
    // Repeat the pattern if more colors are needed
    "#ff0000", // Vivid Red
    "#ff4500", // Bright Orange
    "#00ffff", // Bright Cyan
    "#00ff00", // Vivid Green
  ];
  
  // Updated hoverColors array with contrasting colors
  const hoverColors = [
    "#00ffff", // Bright Cyan for Red
    "#6600ff",  // Electric Purple
    "#00ff00", // Vivid Green for Orange
    "#6600ff",  // Electric Purple
    // "#ff0000", // Vivid Red for Cyan
    "#ff4500", // Bright Orange for Green
    // Repeat the pattern for hover effects
    "#00ffff", // Bright Cyan for Red
    "#00ff00", // Vivid Green for Orange
    "#ff0000", // Vivid Red for Cyan
    "#ff4500", // Bright Orange for Green
  ];
const buttonTexts = ['NinjAi Stats', 'Your Groups', 'Crypto News', 'Games', 'Multiplayer', 'Group Fun', 'game credits', 'Ninjai wiki', 'burn ninjai'];

const IconText = styled.p`

  font-family: 'Ninja', sans-serif;
  position: absolute;
  bottom: -45px; 
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1.5em;
  z-index: 1;
  transition: 0.3s all ease-in-out;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

  @media (max-width: 768px) {
    font-size: 1em; 
  }
`;

// Loading Component
const LoadingContainer = styled.div`
  width: 100%;
  height: 5px;
  background: #FFC8A2; /* Pastel base color */
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingBar = styled(motion.div)`
  background: #FF92C2; /* Pastel progress color */
  height: 100%;
  position: relative;
`;



const Square = ({ active, setActive, colIndex, rowIndex, x, y, openModal }) => {
  const isDragging = colIndex === active.col && rowIndex === active.row;
  const springConfig = {
    stiffness: 1000,
    damping: 20
  };
  const dx = useSpring(x, springConfig);
  const dy = useSpring(y, springConfig);
  const bgColor = colors[colIndex + rowIndex * 3];
  const hoverColor = hoverColors[colIndex + rowIndex * 3]; 
  const buttonIndex = colIndex + rowIndex * 3; 

  const ref = useRef(null); 
  const handleMouseOver = () => {
    gsap.to(ref.current, {
      boxShadow: `0px 0px 20px 5px ${hoverColor}`,
      repeat: -1,
      yoyo: true,
      duration: 1,
      ease: "power1.inOut",
    });
  };

  const handleMouseOut = () => {
    gsap.killTweensOf(ref.current);
    ref.current.style.boxShadow = 'none';
  };

  return (
    <motion.div
      drag
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      dragTransition={{ bounceStiffness: 500, bounceDamping: 10 }}
      dragElastic={1}
      onDragStart={() => setActive({ row: rowIndex, col: colIndex })}
      onClick={() => openModal(colIndex + rowIndex * 3)}
      whileHover={{ scale: 1.2, backgroundColor: hoverColor }}
      style={{
        background: bgColor,
        backgroundImage: `url(${buttons[buttonIndex]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: size,
        height: size,
        top: rowIndex * (size + gap),
        left: colIndex * (size + gap),
        position: "absolute",
        borderRadius: "10%",
        x: isDragging ? x : dx,
        y: isDragging ? y : dy,
        zIndex: isDragging ? 1 : 0,
        cursor: "pointer"
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      ref={ref}
    >
      <IconText>{buttonTexts[buttonIndex]}</IconText>
    </motion.div>
  );
};

const Home = () => {
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState(null);

  useEffect(() => {
      const fetchSession = async () => {
          try {
              const response = await axios.get('/api/get-session');
              const { userId } = response.data;
              if (userId && userId !== userId) {
                  setUserId(userId);
              }
          } catch (error) {
              console.error('Error fetching session:', error);
          }
      };

      fetchSession();
  }, [userId]);

  useEffect(() => {
      if (userId) {
          const fetchUserData = async () => {
              try {
                  const response = await axios.post('/api/fetch-username', { userId });
                  const { username } = response.data;
                  setUsername(username);
              } catch (error) {
                  console.error('Error fetching user data:', error);
              }
          };

          fetchUserData();
      }
  }, [userId]);

  const [loadingProgress, setLoadingProgress] = useState(0);  // state for loading progress
  const [isLoaded, setIsLoaded] = useState(false); // state to track when images are loaded
  
  useEffect(() => {
    let loadCount = 0;
    const imageElements = buttons.map((url, index) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadCount += 1;
        setLoadingProgress(Math.floor((loadCount / buttons.length) * 100));
        if(loadCount === buttons.length) {
          setIsLoaded(true);
        }
      };
      return img;
    });
    // Cache images
    window.cachedImages = imageElements;
  }, []);
  const [active, setActive] = useState({ row: 0, col: 0 });
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const [isOpen, setIsOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);

  const components = [
    <AboutText username={username} />,
  ];

  const openModal = (index) => {
    setActiveComponent(components[index]);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="app">
      {!isLoaded && <Loading progress={loadingProgress} />}
      {isLoaded && (
        <motion.div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
          }}
        >
            <Background />
          <GridWrapper size={size} gap={gap}>
            {grid.map((row, rowIndex) =>
              row.map((_item, colIndex) => (
                <Square
                  x={x}
                  y={y}
                  active={active}
                  setActive={setActive}
                  rowIndex={rowIndex}
                  colIndex={colIndex}
                  key={rowIndex + colIndex}
                  openModal={openModal}
                />
              ))
            )}
          </GridWrapper>
        </motion.div>
      )}
      {isOpen && (
        <KawaiiModal>
          <Background maximized={isOpen} />
          {activeComponent}
          <CloseButton onClick={closeModal}>🥷🏼</CloseButton>
        </KawaiiModal>
      )}
    </div>
  );
};

export default Home;
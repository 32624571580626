// import React, { useState, useEffect } from 'react'; // for screen rotation blocker
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Frame from './components/Auth/ComponentFrame/jsx/Frame.jsx';
import PasswordReset from './components/Auth/ForgotPassword/PostAuthEmail/PasswordReset.jsx';
// import RotateScreenPrompt from './components/Helpers/RotateScreen/jsx/RotateScreenPrompt';
import Dashboard from './components/Dashboard/MainDash/jsx/Dashboard.jsx';
import PreChecks from './components/Helpers/TelegramLink/jsx/PreChecks.jsx';
// import Background from './components/Dashboard/Components/Modules/HomePage/jsx/Background';
// import { inject } from '@vercel/analytics';
// inject();


function App() {

  // const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsPortrait(window.innerHeight > window.innerWidth);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, );

  return (
      <Router>
        {/* <Background /> */}
        {/* {isPortrait && <RotateScreenPrompt />} */}
        <Routes>
          <Route path="/" element={<Frame />} />
          <Route path="/forgot-password" element={<PasswordReset />} />
          <Route path="/prechecks" element={<PreChecks />} />
          <Route path="/dashboard" element={<Dashboard />} />
          {/* ... other routes ... */}
        </Routes>
      </Router>
  );
}


export default App;

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useSupabase } from '../../../../SupabaseContext';
import axios from 'axios';
import styled from 'styled-components';

const AccountLinkerCSS = styled.div`
  .account-linker-container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.8);
  animation: borderGlow 2s infinite;
  font-family: 'Ninja', sans-serif;
  width: 80%;
  overflow: hidden;
  }
  
  .account-linker-container:before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: linear-gradient(45deg, #ff0000, transparent, #00ddff);
  border-radius: 24px;
  z-index: -1;
  filter: blur(8px);
  opacity: 0.7;
  animation: pulsate 2s linear infinite;
  }
  
  .account-linker-container input {
    padding: 100px;
    border-radius: 20px;
    background: transparent;
    color: rgb(173, 165, 165);
    outline: none;
    text-align: center;
    animation: pulsate 3s infinite;
    font-family: 'Ninja', sans-serif;
    border: none;
    width: 100%;
    font-size: 2em;
    }
  
  .account-linker-container button {
  padding: 20px;
  border-radius: 20px;
  background: transparent;
  color: rgb(173, 165, 165);
  outline: none;
  text-align: center;
  animation: pulsate 3s infinite;
  font-family: 'Ninja', sans-serif;
  border: none;
  width: 100%;
  font-size: 2em;
  }
  
  .account-linker-container button {
  background: linear-gradient(45deg, #1a1a1a, #2d2d2d);
  border-radius: 20px;
  color: rgb(177, 177, 177);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 255, 157, 0.7);
  }
  
  .center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh; 
  }
  
  .error-message {
  color: red;
  text-align: center;
  font-family: 'Ninja', sans-serif;
  margin-top: 10px;
  font-size: 1em;
  }
  
  
  @keyframes pulsate {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
  }
  
  @keyframes borderGlow {
  0% { box-shadow: 0 0 5px #ff0000; }
  50% { box-shadow: 0 0 20px #ff0000; }
  100% { box-shadow: 0 0 5px #ff0000; }
  }
  
  @media (max-width: 768px) {
  .account-linker-container {
    padding: 30px;
  }
  
  .account-linker-container input {
  padding: 60px;
  font-size: 1em;
  }
  .account-linker-container button {
    padding: 20px;
    font-size: 1em;
  }
  }  
`;

const AccountLinker = ({ userId, onLinkSuccess, onLinkFailure }) => {
    const [token, setToken] = useState('');
    const [linked, setLinked] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkUserLink = async () => {
            try {
                const response = await axios.post('/api/check-user-link', { userId });
                const { linked } = response.data;
                setLinked(linked);
            } catch (error) {
                console.error('Error fetching user link:', error);
                setError('Error fetching user link: ' + error.message);
                onLinkFailure();
            }
        };

        if (userId) {
            checkUserLink();
        }
    }, [userId, onLinkFailure]);

    const handleTokenSubmit = async () => {
        try {
            const response = await axios.post('/api/linkAccount', {
                userId: userId,
                token: token
            });

            if (response.data.success) {
                setLinked(true);
                onLinkSuccess();
            } else {
                throw new Error(response.data.message || 'Linking failed');
            }
        } catch (error) {
            setError('No matching record found for the given token. Send the /start command in a direct message to @theninjaibot on telegram to obtain a token');
            onLinkFailure();
        }
    };

    if (linked || !userId) return null;
    console.log('Rendering AccountLinker component');
    if (linked || !userId) return null;
    return (
        <AccountLinkerCSS>
            <div className="center-container">
                <motion.div
                    className="account-linker-container"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <input
                        type="text"
                        placeholder="Enter your unique Telegram token"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                    />
                    <button onClick={handleTokenSubmit}>Link Telegram Account</button>
                    {error && <div className="error-message">{error}</div>}
                </motion.div>
            </div>
        </AccountLinkerCSS>
    );
};

export default AccountLinker;